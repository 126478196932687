import React, { useState } from "react";
import Service from '../../../services/dict/DictCategoryService';
import { withTranslation } from "react-i18next";
import Modal from "../../../constants/Modal";

type Props = {
    item: string;
    onDeleteCategory: (id: string) => void;
    t: any;
};

const Options: React.FC<Props> = ({ item, onDeleteCategory, t }) => {
    const [showModal, setShowModal] = useState(false);
    
    const openDeleteModal = () => {
        setShowModal(true);
    };

    const deleteCategory = (itemId: string) => {
        Service.deleteDictCategory(itemId)
            .then(() => {
                onDeleteCategory(itemId);
                setShowModal(false);
            })
            .catch(error => {
                console.error('Error deleting category:', error);
            });
    };

    return (
        <div>
            <img 
                src="/images/navigation/options.svg" 
                alt="Options"
                style={{ cursor: "pointer", width: "24px", height: "24px" }} 
                onClick={openDeleteModal} 
            />
            
            <Modal show={showModal} onClose={() => setShowModal(false)} title={t("table.options")}>  
                <div className="option-buttons">
                    <label htmlFor={t("buttons.delete")} className="icon-option-big">
                        <img src="/images/navigation/bin.svg" onClick={() => deleteCategory(item)} />
                    </label>
                </div>
            </Modal>
        </div>
    );
}

export default withTranslation("global")(Options);
