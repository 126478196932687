import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import UserService from "../../services/user.service";

type Props = {
  t: any;
};

type State = {
  content: string;
};

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      (response) => {
        this.setState({
          content: response.data
        });
      },
      (error: { response?: { data: string }; message?: string }) => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            "An error occurred"
        });
      }
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className="card-container">
        <div className="center" >
          <Link to={"/login"} className="nav-link">
            <img src="/images/navigation/log_in.svg" alt="zapiszTo" />
            <span className="nav-text">{t("navigation.log_in")}</span>
          </Link>
          <Link to={"/register"} className="nav-link">
            <img src="/images/navigation/register.svg" alt="zapiszTo" />
            <span className="nav-text">{t("navigation.sign_in")}</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default withTranslation("global")(Home);
